<template>
  <el-dialog
    class="report-modal"
    @close="reset"
    :visible.sync="value"
    width="45%"
    destroy-on-close
    :show-close="!loading"
    :close-on-click-modal="false"
    title="导入知识图谱"
  >
    <div class="dialog-content">
      <el-form>
        <el-form-item label="上传文件" label-width="100px">
          <el-upload
            :show-file-list="false"
            accept=".xls,.xlsx"
            class="upload-demo"
            :disabled="file !== null"
            :before-upload="handleBeforeUpload"
            drag
            action=""
          >
            <el-button class="el-button" style="width: 350px;height: 37px;" size="small"
              ><YTIcon
                :href="'#icon-daoruwenjian-gaoliangzhuangtaiicon'"
                v-show="!file"
                style="width: 19px;height: 14px;margin-right: 5px; color:#CCCCCC;"
              />
              {{ file ? file.name : '请上传文件格式为xls，大小不超过5M' }}
              <YTIcon @click="delFIle" v-show="file" href="#icon-shanchu" class="position-style" />
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label-width="100px">
          <p class="tip-font">
            1.请根据系统提供的模板导入，
            <span class="click-span">点击 <a @click="down">下载模板</a></span>
          </p>
          <p class="tip-font">
            2.文件权限excel类型，文件格式为xls，大小不超过5M
          </p>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog width="30%" title="确认覆盖上传" :visible.sync="innerVisible" append-to-body>
      <p style="text-align: center">已存在同名的知识点，是否覆盖上传</p>
      <div slot="footer" style="text-align: center">
        <el-button :loading="loading" @click="innerVisible = false">取消</el-button>
        <el-button :loading="loading" @click="uploadFile" type="primary">确定</el-button>
      </div>
    </el-dialog>
    <div slot="footer" style="text-align: center">
      <el-button :loading="loading" @click="reset">取消</el-button>
      <el-button :loading="loading" @click="upload" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import knowledgeApi from '@api/knowledge'
export default {
  name: 'KnowledgeReportModal',
  components: { YTIcon },
  data() {
    return {
      value: false,
      innerVisible: false,
      loading: false,
      fileUpload: null,
      file: null
    }
  },
  methods: {
    async upload() {
      // 上传
      if (this.file) {
        this.loading = true
        let formData = new FormData()
        formData.append('file', this.file)
        let verify = await knowledgeApi.uploadVerify(formData).finally(() => {
          this.loading = false
        })
        if (verify.code === 0) {
          this.uploadFile()
        } else if (verify.code !== 6006) {
          this.innerVisible = true
        }
      } else {
        this.$message.warning('请选择上传的文件')
      }
    },
    uploadFile() {
      let formData = new FormData()
      formData.append('file', this.file)
      knowledgeApi
        .uploadExcel(formData)
        .then(res => {
          if (res.res && res.code === 0) {
            this.$message.success('导入成功')
            this.innerVisible = false
            this.$parent.getTags(this.$parent.tagIndex)
          }
        })
        .finally(() => {
          this.value = false
          this.loading = false
        })
    },
    down() {
      // 下载模板
      knowledgeApi.downloadKnowledge().then(res => {
        // util.exportExamPeoInfo(res, '知识图谱导入模板', 'xlsx')
        this.$downloadFlow(res, '知识图谱导入模板', 'xls')
      })
    },
    reset() {
      this.file = null
      this.value = false
    },
    delFIle() {
      // 删除文件
      this.file = null
    },
    open() {
      this.value = true
    },
    handleBeforeUpload(file) {
      let fileSuffix = this.suffix(file.name)
      if (fileSuffix !== '.xls') {
        return this.$message.warning('请上传xls格式的文件')
      }
      this.file = file
      return false
    },
    suffix(name) {
      let type = name.substr(name.lastIndexOf('.'), name.length)
      return type
    }
  }
}
</script>

<style lang="less" scoped>
.report-modal {
  .font(14px, Regular, #000);
  .tip-font {
    color: #ccc;
    .click-span {
      color: #000;
      a {
        text-decoration: underline;
      }
    }
  }
  ::v-deep {
    .upload-demo {
      .el-upload-dragger {
        display: flex;
        align-items: flex-start;
        border: 0;
        height: auto;
      }
      .el-icon-upload {
        width: 19px;
        height: 14px;
      }
    }
    .el-dialog {
      margin-top: 15vh !important;
      min-width: 520px;
      max-width: 520px;
    }
    .el-dialog__body {
      padding-bottom: 0px !important;
    }
  }
}
</style>
