<template>
  <div style="height: 100%;">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>图谱编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="display: flex;position: relative; height: 100%; padding: 20px">
      <div>
        <Button icon="ios-add" type="dashed" @click="toAddKnowledge" style="margin: 10px 0;width: 100%">添加知识点</Button>
        <div class="root">
          <div class="tag" :class="tagIndex === index ? 'checked' : ''" v-for="(item, index) in tags" :key="index" @click="choose(index, item)">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div style="flex: auto;margin-left: 10px; height: 100%">
        <div ref="chart" class="chart" />
      </div>
      <div class="reset-button" @click="resetTree">重置</div>
      <div class="report-button reset-button" @click="reportVal">导入</div>
    </div>
    <el-dialog
      :visible="showAddPost"
      :title="isEdit ? '编辑知识点' : '添加知识点'"
      width="484"
      class="yt-dialog yt-dialog-default"
      @on-cancel="cancelAdd"
      @close="cancelAdd"
    >
      <el-form :model="postForm" ref="postForm" :rules="postFormValidate" label-width="132px" class="yt-form yt-dialog-form">
        <el-form-item label="知识点名称：" prop="name">
          <el-input :maxlength="50" show-word-limit v-model="postForm.name" />
        </el-form-item>
        <el-form-item label="知识点描述：" prop="description">
          <el-input :maxlength="50" show-word-limit v-model="postForm.description" />
        </el-form-item>
      </el-form>
      <div class="footer" slot="footer">
        <el-button class="button" @click="cancelAdd">取消</el-button>
        <el-button class="button" type="primary" @click="addPost" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible="abilityModal" title="添加能力" width="484" class="yt-dialog yt-dialog-default" @on-cancel="cancelAddA" @close="cancelAddA">
      <el-form :model="abilityForm" :label-width="85" ref="abilityForm" :rules="abilityValidate">
        <el-form-item label="能力名称：" prop="name">
          <el-input v-model="abilityForm.name" />
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button class="button" @click="cancelAddA">取消</el-button>
        <el-button class="button" type="primary" @click="addAbility" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
    <Modal v-model="moveModal" title="移动岗位" @on-cancel="cancelMove">
      <div style="display: flex;justify-content: center;white-space: nowrap;">
        <div style="font-size: 14px;margin: 5px 5px 0 0">移动岗位 {{ moveName }} 到</div>
        <Form :model="moveForm" ref="moveForm" :rules="moveValidate" :hide-required-mark="true" style="flex: auto">
          <FormItem prop="moveId">
            <Cascader :data="moveData" :load-data="loadData" v-model="moveForm.moveId"></Cascader>
          </FormItem>
        </Form>
      </div>
      <div slot="footer" style="text-align: center">
        <Button @click="cancelMove">取消</Button>
        <Button type="primary" @click="movePost" :loading="loading">确定</Button>
      </div>
    </Modal>
    <div id="popper" @mouseleave="hideP" @mouseenter="inPopper = true">
      <Button
        type="text"
        size="small"
        @click="
          isRoot = false
          isEdit = false
          showAddPost = true
          postForm.name = ''
          postForm.description = ''
        "
        >添加知识点</Button
      >
      <Button
        type="text"
        size="small"
        @click="
          isEdit = true
          showAddPost = true
        "
        >编辑知识点</Button
      >
      <!--      <Button type="text" size="small" @click="moveModal = true">移动知识点</Button>-->
      <Button type="text" size="small" @click="delPost(postForm.parentId)">删除知识点</Button>
      <Button type="text" size="small" @click="moveKnowledge">移动知识点</Button>
    </div>
    <Button v-if="abilityBtn" icon="ios-add" type="dashed" @click="abilityModal = true" style="position: absolute;top: 50vh;right: 10vw"
      >添加能力</Button
    >
    <KnowledgeMoveDialog ref="knowledgeMove" @refresh="getTags" />
    <!--  知识图谱导入modal  -->
    <KnowledgeReportDialog ref="reportRef" />
  </div>
</template>

<script>
import functionApi from '@api/functionApi'
import knowledge from '@api/knowledge'
import icon from '@assets/caozuo.png'
import KnowledgeMoveDialog from '@components/common/dialog/KnowledgeMoveDialog'
import KnowledgeReportDialog from '@/components/common/dialog/KnowledgeReportDialog'
export default {
  name: 'Knowledge',
  components: {
    KnowledgeReportDialog,
    KnowledgeMoveDialog
  },
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!value) {
        callback(new Error('知识点名称不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('知识点名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      isRoot: false,
      abilityBtn: false,
      abilityModal: false,
      abilityForm: {
        name: ''
      },
      abilityValidate: {
        name: [{ required: true, message: '知识点名称不能为空', trigger: 'blur' }]
      },
      moveData: [],
      moveName: '',
      moveForm: {
        moveId: []
      },
      moveModal: false,
      moveValidate: {
        moveId: [{ required: true, message: '请选择移动到的岗位', trigger: 'blur' }]
      },
      inPopper: false,
      loading: false,
      isEdit: false,
      postFormValidate: {
        name: [{ required: true, validator: validateName, trigger: 'blur' }],
        description: [
          {
            type: 'string',
            max: 50,
            message: '只能输入50位字符',
            trigger: 'blur'
          }
        ]
      },
      postForm: {
        description: '',
        name: '',
        parentId: 0
      },
      showAddPost: false,
      tags: [],
      tagIndex: 0,
      treeChart: null,
      ds: {
        series: [
          {
            type: 'tree',
            name: '知识点',
            initialTreeDepth: 10,
            data: [],
            top: '5%',
            left: '15%',
            bottom: '0',
            right: '10%',
            symbolSize: 25,
            symbol: `image://${icon}`,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right'
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            roam: true,
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      }
    }
  },
  created() {
    this.getTags()
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    reportVal() {
      // 导入模板
      this.$refs['reportRef'].open()
    },
    toAddKnowledge() {
      this.isRoot = true
      this.isEdit = false
      this.postForm.name = ''
      this.postForm.parentId = 0
      this.postForm.description = ''
      this.showAddPost = true
    },
    resize() {
      if (this.treeChart !== null) {
        this.treeChart.resize()
      }
    },
    addAbility() {
      this.$refs['abilityForm'].validate(valid => {
        if (valid) {
          this.loading = true
          const payload = {
            name: this.abilityForm.name,
            parentId: this.postForm.parentId
          }
          functionApi
            .addAbility(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success('添加成功')
                this.cancelAddA()
                this.loading = false
                this.abilityBtn = false
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    cancelAddA() {
      this.$refs['abilityForm'].resetFields()
      this.abilityModal = false
    },
    loadData(item, callback) {
      item.loading = true
      functionApi.getTree(item.value).then(res => {
        item.children = res.res.map(item => {
          item.value = item.id
          item.label = item.name
          return item
        })
        item.loading = false
        callback()
      })
    },
    movePost() {
      this.$refs['moveForm'].validate(valid => {
        if (valid) {
          this.loading = true
          const payload = {
            postId: this.postForm.parentId,
            toPostId: this.moveForm.moveId[this.moveForm.moveId.length - 1]
          }
          functionApi
            .movePost(payload)
            .then(() => {
              this.$message.success('移动成功')
              this.getTags(this.tagIndex)
              this.cancelMove()
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
    cancelMove() {
      this.$refs['moveForm'].resetFields()
      this.moveModal = false
    },
    getAbility(params) {
      functionApi.getAbility(params.data.id).then(res => {
        if (Array.isArray(res.res)) {
          this.ds.series[1].data = [
            {
              children: res.res
            }
          ]
          this.treeChart.setOption(this.ds, true)
        } else {
          this.abilityBtn = true
        }
      })
    },
    showP(params) {
      if (params.seriesIndex === 0) {
        this.postForm.parentId = params.data.id
        this.postForm.name = params.name
        this.postForm.description = params.data.description
        this.moveName = params.data.name
        let a = document.getElementById('popper')
        a.style.display = 'block'
        a.style.left = params.event.event.clientX.toString() + 'px'
        a.style.top = (params.event.event.clientY + 15).toString() + 'px'
      }
    },
    unShowP(params) {
      if (params.seriesIndex === 0) {
        setTimeout(() => {
          if (!this.inPopper) {
            this.hideP()
          }
        }, 2000)
      }
    },
    hideP() {
      this.inPopper = false
      let a = document.getElementById('popper')
      a.style.display = 'none'
      a.style.left = '-9999px'
      a.style.top = '-9999px'
    },
    choose(i, item) {
      this.tagIndex = i
      this.getTree(item.id)
      this.treeChart.dispose()
      this.treeChart = null
    },
    getTags(i) {
      knowledge.getKnowledgeRoot().then(res => {
        let arr = res.res.filter(item => {
          if (item.status === false) {
            return item
          }
        })
        this.tags = arr
        if (this.tagIndex === arr.length) {
          this.tagIndex = 0
        }
        if (this.tags.length > 0) {
          this.getTree(arr[this.tagIndex].id)
        } else if (this.treeChart !== null) {
          this.treeChart.dispose()
        }
        this.moveData = arr.map(item => {
          return {
            label: item.name,
            children: [],
            loading: false,
            value: item.id
          }
        })
      })
    },
    getTree(id) {
      knowledge.getKnowledgeChildren(id).then(res => {
        this.ds.series[0].data = [
          {
            id: res.res.id,
            name: res.res.name,
            description: res.res.description,
            children: res.res.children
          }
        ]
        let treeHeight = 0
        res.res.children.map(item => {
          if (item.children.length === 0) {
            treeHeight += 25
          } else {
            treeHeight += item.children.length * 25
          }
        })
        this.ds.series[0].height = treeHeight
        this.$nextTick(() => {
          if (this.treeChart === null) {
            this.treeChart = this.$echarts.init(this.$refs['chart'])
            this.treeChart.on('mouseover', this.showP)
            this.treeChart.on('mouseout', this.unShowP)
          }
          this.treeChart.setOption(this.ds, true)
        })
      })
    },
    resetTree() {
      let treeHeight = 0
      this.ds.series[0].data[0].children.map(item => {
        treeHeight += item.children.length * 25
      })
      this.ds.series[0].height = treeHeight
      this.$set(this.ds.series[0], 'top', '5%')
      this.$set(this.ds.series[0], 'left', '15%')
      this.$set(this.ds.series[0], 'right', '10%')
      this.treeChart.setOption(this.ds, true)
    },
    addPost() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          this.loading = true
          if (this.isEdit) {
            const payload = {
              description: this.postForm.description,
              id: this.postForm.parentId,
              name: this.postForm.name
            }
            knowledge
              .updateKnowledge(payload)
              .then(res => {
                if (res.code === 0) {
                  this.$message.success('编辑知识点成功')
                  this.getTags()
                  this.cancelAdd()
                } else {
                  this.loading = false
                }
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            if (this.isRoot) {
              knowledge
                .nodeAdd(this.postForm)
                .then(res => {
                  if (res.code === 0) {
                    this.$message.success('添加知识点成功')
                    this.getTags()
                    this.cancelAdd()
                  } else {
                    this.loading = false
                  }
                })
                .catch(() => {
                  this.loading = false
                })
            } else {
              knowledge
                .addSubKnowledge('', this.postForm)
                .then(res => {
                  if (res.code === 0) {
                    this.$message.success('添加知识点成功')
                    this.getTags()
                    this.cancelAdd()
                  } else {
                    this.loading = false
                  }
                })
                .catch(() => {
                  this.loading = false
                })
            }
          }
        }
      })
    },
    delPost(id) {
      this.$Modal.confirm({
        title: '删除知识点',
        content: '<p>确认删除该知识点吗？</p>',
        onOk: () => {
          knowledge.deleteKnowledge(id).then(res => {
            if (res.code === 0) {
              this.$message.success('删除成功')
              this.getTags()
            }
          })
        }
      })
    },
    cancelAdd() {
      this.showAddPost = false
      this.$refs['postForm'].resetFields()
      this.loading = false
    },
    moveKnowledge() {
      this.$refs.knowledgeMove.open(this.postForm.parentId)
    }
  }
}
</script>

<style scoped lang="less">
.root {
  height: calc(100vh - 180px);
  overflow: auto;
}
::v-deep .yt-dialog-form {
  text-align: left;
}
.chart {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
.tag {
  height: 32px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #e8eaec;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  white-space: nowrap;
}
.checked {
  background: #2d8cf0;
  color: white;
}
#popper {
  background: white;
  font-size: 24px;
  border: 1px solid #e8eaec;
  width: 80px;
  position: absolute;
  left: -9999px;
  top: -9999px;
}
.reset-button {
  padding: 0 5px;
  height: 20px;
  border-radius: 3px;
  color: #b0b0b0;
  border: 1px solid #b0b0b0;
  position: absolute;
  right: 5%;
  top: 10px;
  z-index: 10;
  cursor: pointer;
}
.report-button {
  right: 9%;
}
</style>
